import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import { darkerGray, gray, smallText, red } from 'src/Styles/settings/Constants'

const FileInstructionsFragment = ({
  dimensionsText,
  typeError,
  sizeError
}) => (
  <Fragment>
    <Grid.Col cols={3}>
      <Grid.Row auto noMargin>
        <Label fontSize={smallText} color={gray}>
          Formato
        </Label>
      </Grid.Row>
      <Grid.Row auto>
        <Label
          bold={typeError}
          fontSize={smallText}
          color={typeError ? red : darkerGray}>
          PNG
        </Label>
      </Grid.Row>
    </Grid.Col>
    <Grid.Col cols={8}>
      <Grid.Row auto noMargin>
        <Label fontSize={smallText} color={gray}>
          Dimensões
        </Label>
      </Grid.Row>
      <Grid.Row auto>
        <Label fontSize={smallText} color={darkerGray}>
          {dimensionsText}
        </Label>
      </Grid.Row>
    </Grid.Col>
    <Grid.Col cols={5}>
      <Grid.Row auto noMargin>
        <Label fontSize={smallText} color={gray}>
          Tamanho Máximo
        </Label>
      </Grid.Row>
      <Grid.Row auto>
        <Label
          bold={sizeError}
          fontSize={smallText}
          color={sizeError ? red : darkerGray}>
          10kb
        </Label>
      </Grid.Row>
    </Grid.Col>
  </Fragment>
)

FileInstructionsFragment.propTypes = {
  dimensionsText: PropTypes.string,
  typeError: PropTypes.bool,
  sizeError: PropTypes.bool
}

export default FileInstructionsFragment
