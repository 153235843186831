import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import TopHeaderMarketplace from 'src/Components/TopHeader/TopHeaderMarketplace'
import TopHeaderSeller from 'src/Components/TopHeader/TopHeaderSeller'
import { BackofficeDisclaimer } from 'src/UIComponents/BackofficeDisclaimer/BackofficeDisclaimer'
import { loadTrackSale } from '../../Utils/externalScript'

const TopHeader = () => {
  const {
    showBackofficeDisclaimer,
    seller,
    user
  } = useSelector(({ authentication, context, user }) => ({
    showBackofficeDisclaimer: authentication.accessOrigin === 'backoffice',
    seller: context.seller,
    user
  }))

  useEffect(() => {
    loadTrackSale(
      {
        key: 'e61818e84e01a2d7',
        campaign: '0e73d2c2',
        name: `${user.firstName} ${user.lastName}`,
        email: user.username,
        salute: `${user.firstName}, tudo bem?`,
        thanks: `Obrigado, ${user.firstName}`
      }
    )
  }, [])

  return (
    <>
      {showBackofficeDisclaimer && <BackofficeDisclaimer />}
      { seller
        ? <TopHeaderSeller />
        : <TopHeaderMarketplace />
      }
    </>
  )
}

TopHeader.propTypes = {
  history: PropTypes.object
}

export default TopHeader
