import React, { Fragment, useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { getTransferDetail, getTransferHistory } from 'src/Actions/transfers'
import { parseDate } from 'src/Utils/Utils'

import DateLoader from 'src/Components/TransferDetail/DateLoader'
import Grid from 'src/UIComponents/Grid/Grid'
import Collapse from 'src/UIComponents/Collapse/Collapse'
import Detail from 'src/UIComponents/TransferDetail/Detail/Detail'
import TransferHistory from 'src/UIComponents/TransferDetail/History/TransferHistory'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'

import EmptyState from 'src/UIComponents/EmptyState/EmptyState'

const TransferDetail = ({ handleClose, handleGoBack, transferId, isAdjustment }) => {
  const dispatch = useDispatch()
  const limit = 10
  const [page, setPage] = useState(0)
  const [historyCollapseOpen, setHistoryCollapseOpen] = useState(false)

  const {
    transferDetail,
    transferDetailRequesting,
    transferDetailError,
    transferSender,
    transferSenderDetailRequesting,
    transferRecipient,
    transferRecipientDetailRequesting,
    transferHistory,
    transferQueryCount,
    transferHistoryRequesting
  } = useSelector(({ transfers }) => {
    const {
      transferDetail,
      transferDetailRequesting,
      transferDetailError,
      transferSender,
      transferSenderDetailRequesting,
      transferSenderDetailError,
      transferRecipient,
      transferRecipientDetailRequesting,
      transferRecipientDetailError,
      transferHistory,
      transferQueryCount,
      transferHistoryRequesting
    } = transfers

    return {
      transferDetail,
      transferDetailRequesting,
      transferDetailError,
      transferSender,
      transferSenderDetailRequesting,
      transferSenderDetailError,
      transferRecipient,
      transferRecipientDetailRequesting,
      transferRecipientDetailError,
      transferHistory,
      transferQueryCount,
      transferHistoryRequesting
    }
  })

  const fetchMoreHistory = () => {
    const newPage = page + 1
    setPage(newPage)
    dispatch(getTransferHistory(transferId, { limit, offset: newPage * limit }))
  }

  useEffect(() => {
    dispatch(getTransferDetail(transferId, limit, isAdjustment))
  }, [transferId])

  const isRequesting = transferDetailRequesting ||
    transferSenderDetailRequesting ||
    transferRecipientDetailRequesting

  const subtitle = () => {
    if (transferDetailRequesting) {
      return <DateLoader />
    } else if (transferDetail) {
      return parseDate(transferDetail.created_at, '[Criada em] DD [de] MMMM [de] YYYY [às] HH:mm:ss[h]')
    }
  }

  let error = null

  if (transferDetailError) {
    error = {
      icon: transferDetailError.message.icon,
      title: transferDetailError.message.title,
      description: transferDetailError.message.description
    }

    if (transferDetailError.status === 404) {
      error = {
        icon: '/assets/icons/alerts/alert.svg',
        title: 'Desculpe, o detalhe desta transferência não foi encontrado.',
        description: 'Erro 404'
      }
    }
  }

  return (
    <Fragment>
      <HeaderSidebar
        title={<><strong>Detalhe</strong> da transferência</>}
        subTitle={subtitle()}
        handleClose={handleClose}
        goBack={handleGoBack}
      />
      <SidebarContainer>
        {!error && <>
          <Grid.Row auto bigMarginBottom>
            <Detail
              requesting={isRequesting}
              transfer={transferDetail}
              recipient={transferRecipient}
              sender={transferSender} />
          </Grid.Row>
          <Grid.Row auto>
            <Collapse
              isOpen={historyCollapseOpen}
              data-test='transfer-detail-collapse-history'
              onClick={() => {
                setHistoryCollapseOpen(!historyCollapseOpen)
              }}
              title={<><strong>Extrato</strong> da transferência</>}
              borderBottom={false}>
              <TransferHistory
                transfer={transferDetail}
                transferHistory={transferHistory}
                transferQueryCount={transferQueryCount}
                transferHistoryHasMore={transferQueryCount > transferHistory.length}
                transferHistoryRequesting={transferHistoryRequesting}
                fetchMoreData={fetchMoreHistory}
                requesting={isRequesting} />
            </Collapse>
          </Grid.Row>
        </>}
        {error && (
          <>
            <br />
            <br />
            <br />
            <br />
            <EmptyState
              titleSize='big'
              iconSrc={error.icon}
              title={error.title}
              description={error.description} />
          </>
        )}
      </SidebarContainer>
    </Fragment>
  )
}

TransferDetail.propTypes = {
  handleClose: PropTypes.func,
  handleGoBack: PropTypes.func,
  transferId: PropTypes.string,
  isAdjustment: PropTypes.bool
}

export default TransferDetail
