import React from 'react'
import PropTypes from 'prop-types'

import Card from 'src/UIComponents/Card/Card'
import Label from 'src/UIComponents/Label/Label'
import styled from 'styled-components'
import { bigText, gray } from 'src/Styles/settings/Constants'
import Button from 'src/UIComponents/Button/ButtonDefault'

const CookieNotice = ({ onClick, onClose }) => {
  return (
    <CookieNoticeBox>
      <Card
        withShadow
        buttonClose
        handleClose={onClose}
        padding={'30px 0'}
        noTheme
      >
        <Label
          fontSize={bigText}
          textAlign='center'
          color={gray}
        >
          Usamos cookies para melhorar sua experiência de navegação. Ao continuar navegando, você concorda com nossa <Button onClick={onClick} link widthAuto >Política de Cookies.</Button>
        </Label>
      </Card>
    </CookieNoticeBox>
  )
}

const CookieNoticeBox = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 30px;
`

CookieNotice.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func
}

export default CookieNotice
