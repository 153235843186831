import * as PropTypes from 'prop-types'

import React, { useEffect } from 'react'
import { usePagination, useSortBy, useTable } from 'react-table'
import Chevron from '../../UIComponents/Chevron/Chevron'
import Table from '../../UIComponents/Table/SectionedTable'
import { toMoney } from '../../Utils/Utils'

const SectionedTable = ({
  className,
  loading,
  tableColumns,
  tableData,
  pageSize,
  onResultsPerPageChange,
  totalItems,
  totalPages,
  currentPage,
  onPageChange,
  onSortChange,
  error,
  emptyState,
  chessBackground,
  onItemClick,
  dataTracking,
  showPageSizeOptions,
  showPagination,
  manual,
  defaultSortBy,
  mini,
  isBanking,
  typeChange
}) => {
  const onClickIconColumn = {
    id: 'onClickIcon',
    width: '5%',
    Cell: (row) => (
      <div style={{ padding: '15px', display: 'flex', justifyContent: 'flex-end' }} data-test={`table-row-icon-${row.index}`}>
        <span className='detail-icon'>
          <Chevron />
        </span>
      </div>
    )
  }
  /**
   * This function is to format the data into an array of arrays.
   * @param {Array<Object<any>>} data
   * @returns {Array<any>}
   */
  const dataFormat = (data) => {
    if (data?.length === 0 || data == undefined) return []

    if (data?.[0].balance == undefined) return []

    const dataFormatted = data?.map((extractItem) => {
      const arrayWithOnlyItems = []
      arrayWithOnlyItems.push({
        date: extractItem?.date,
        first: true,
        positive: extractItem?.balance >= 0,
        balance: toMoney(extractItem?.balance / 100),
        blocked_balance: toMoney(extractItem?.blocked_balance / 100)
      })
      arrayWithOnlyItems.push(...extractItem.items)
      return arrayWithOnlyItems
    })
    return dataFormatted.flat()
  }

  const formattedData = React.useMemo(() => {
    return dataFormat(tableData)
  }, [tableData])

  const columns = React.useMemo(() => (onItemClick ? [...tableColumns, onClickIconColumn] : tableColumns), [tableColumns])

  const plugins = [useSortBy]

  if (!manual) {
    plugins.push(usePagination)
  }

  const tableInstance = useTable(
    {
      initialState: {
        pageSize: 20,
        pageIndex: 0,
        sortBy: defaultSortBy
      },
      columns,
      data: formattedData || [],
      manualSortBy: manual
    },
    ...plugins
  )

  const {
    pageCount,
    state: { sortBy }
  } = tableInstance

  useEffect(() => {
    onSortChange(sortBy)
  }, [sortBy])

  const onItemClickHandler = (e, item) => {
    e && e.preventDefault()

    if (e.target.getAttribute('name') !== 'copyToClipboard') {
      onItemClick && onItemClick(item)
    }
  }

  return (
    <Table
      className={className}
      tableInstance={tableInstance}
      pageSize={pageSize}
      pageSizeOptions={[30, 50, 100, 200]}
      onResultsPerPageChange={onResultsPerPageChange}
      totalItems={totalItems}
      currentPage={currentPage}
      totalPages={manual ? totalPages : pageCount}
      onPageChange={onPageChange}
      onSortChange={onSortChange}
      loading={loading}
      error={error}
      emptyState={emptyState}
      chessBackground={chessBackground}
      onItemClick={onItemClick ? onItemClickHandler : null}
      dataTracking={dataTracking}
      showPageSizeOptions={showPageSizeOptions}
      showPagination={showPagination}
      manual={manual}
      mini={mini}
      isBanking={isBanking}
      typeChange={typeChange}
    />
  )
}

SectionedTable.defaultProps = {
  chessBackground: true,
  showPagination: true,
  defaultSortBy: [],
  onSortChange: () => {}
}

SectionedTable.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  tableColumns: PropTypes.array,
  tableData: PropTypes.array,
  pageSize: PropTypes.number,
  onResultsPerPageChange: PropTypes.func,
  totalItems: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  currentPage: PropTypes.number,
  error: PropTypes.object,
  emptyState: PropTypes.object,
  chessBackground: PropTypes.bool,
  onItemClick: PropTypes.func,
  dataTracking: PropTypes.string,
  showPageSizeOptions: PropTypes.bool,
  showPagination: PropTypes.bool,
  manual: PropTypes.bool,
  defaultSortBy: PropTypes.array,
  mini: PropTypes.bool,
  isBanking: PropTypes.bool,
  typeChange: PropTypes.string
}

export default SectionedTable
