import React from 'react'
import TopHeaderMarketplaceUI from 'src/UIComponents/TopHeader/TopHeaderMarketplace'

import LogoComponent from 'src/Components/LogoComponent/LogoComponent'
import NavMenuComponent from 'src/Components/NavMenuComponent/NavMenuComponent'
import HeaderUser from 'src/Components/HeaderUser/HeaderUser'
import SelectSellerContext from 'src/Components/SelectSellerContext/SelectSellerContext'
import AddPosComponent from '../AddPosComponent/AddPosComponent'
import AnticipationComponent from '../AnticipationComponent/AnticipationComponent'

const TopHeaderMarketplace = () => {
  return (
    <TopHeaderMarketplaceUI
      headerUser={<HeaderUser />}
      logo={<LogoComponent />}
      menu={<NavMenuComponent />}
      anticipation={<AnticipationComponent />}
      addPos={<AddPosComponent />}
      selectSellerContext={<SelectSellerContext />}
    />
  )
}

export default TopHeaderMarketplace
