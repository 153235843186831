import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import t from 'tcomb-form'
import formValidators from '../../Utils/formValidators'
import { ProfileTypes } from '../../Constants/ProfileTypes'
import { inviteUser } from '../../Actions/seller'
import { getFormOptionsWithValidation } from '../../Utils/FormUtils'

import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import SelectFactory from '../FormFactories/SelectFactory'

import FormZ from '../../UIComponents/FormZ/FormZ'
import Card from '../../UIComponents/Card/Card'
import Grid from '../../UIComponents/Grid/Grid'
import InviteUserTemplate from '../../UIComponents/TemplatesForm/InviteUserTemplate'
import SubTitle from '../../UIComponents/Title/SubTitle'
import ProfileMessage from '../../UIComponents/ProfileMessage/ProfileMessage'
import Button from '../../UIComponents/Button/ButtonDefault'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const InviteUserComponent = () => {
  const [ showValidation, setShowValidation ] = useState(false)
  const [ values, setValues ] = useState({ firstName: '', lastName: '', email: '', profile: '' })
  const [showMFA, setShowMFA] = useState(false)

  const dispatch = useDispatch()
  const { marketplaceId, inviteUserRequest, inviteUserSuccess } = useSelector(({ marketplace, seller }) => ({
    marketplaceId: marketplace && marketplace.details && marketplace.details.id,
    inviteUserRequest: seller.inviteUserRequest,
    inviteUserSuccess: seller.inviteUserSuccess
  }))

  useEffect(() => {
    if (inviteUserSuccess) {
      setValues({ firstName: '', larstName: '', email: '', profile: '' })
    }
  }, [ inviteUserSuccess ])

  const options = {
    template: (locals) => InviteUserTemplate(locals),
    fields: {
      firstName: {
        label: 'Nome',
        factory: InputDefaultFactory,
        autocomplete: false,
        config: {
          id: 'firstName'
        }
      },
      lastName: {
        label: 'Sobrenome',
        factory: InputDefaultFactory,
        autocomplete: false,
        config: {
          id: 'lastName'
        }
      },
      email: {
        label: 'E-mail',
        factory: InputDefaultFactory,
        autocomplete: false,
        config: {
          id: 'email'
        }
      },
      profile: {
        label: 'Perfil de Acesso',
        factory: SelectFactory,
        autocomplete: false,
        config: {
          id: 'profile',
          options: ProfileTypes
        }
      }
    }
  }

  const struct = t.struct({
    firstName: formValidators.Name,
    lastName: formValidators.Name,
    email: formValidators.Email,
    profile: t.Object
  })

  const handleValues = values => {
    setValues(values)
    setShowValidation(false)
  }

  const submitInvite = () => {
    const isFormValid = t.validate(values, struct).isValid()

    if (isFormValid) {
      setShowMFA(true)
    } else {
      setShowValidation(true)
    }
  }

  const onSubmitInvite = () => {
    const { firstName, lastName, email, profile } = values

    const userData = {
      username: email,
      first_name: firstName,
      last_name: lastName
    }

    dispatch(inviteUser(marketplaceId, userData, profile.value))
  }

  return (
    <>
      {showMFA && (
        <MFASingleComponent showComponent={setShowMFA} onSubmit={onSubmitInvite} />
      )}
      <Card padding={'2rem 3rem'}>
        <Grid noPadding>
          <Grid.Row>
            <Grid.Col cols={2}>
              <SubTitle themeColor>Convidar <strong>Usuário</strong></SubTitle>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row auto>
            <FormZ
              options={showValidation
                ? getFormOptionsWithValidation(options, values, struct)
                : options
              }
              struct={struct}
              values={values}
              onChange={handleValues}
            />
          </Grid.Row>
          <Grid.Row>
            <Grid.Col cols={3}>
              <Button
                onClick={submitInvite}
                isLoading={inviteUserRequest}
                data-test='config-usuarios-invite-btn'
              >
              Convidar Usuário
              </Button>
            </Grid.Col>
            {values.profile && values.profile.description && (
              <Grid.Col offset={9} cols={4}>
                <ProfileMessage profile={values.profile.description} />
              </Grid.Col>
            )}
          </Grid.Row>
        </Grid>
      </Card>
    </>
  )
}

export default InviteUserComponent
