import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import t from 'tcomb-form'
import { mapValues } from 'lodash'
import formValidators from '../../Utils/formValidators'
import { addWebhooks, resetWebhooks } from '../../Actions/webhooks'
import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import RadioGroupFactory from '../FormFactories/RadioGroupFactory'
import SelectMultiplyWithGroupFactory from '../FormFactories/SelectMultiplyWithGroupFactory'

import FormZ from '../../UIComponents/FormZ/FormZ'
import Card from '../../UIComponents/Card/Card'
import Grid from '../../UIComponents/Grid/Grid'
import SubTitle from '../../UIComponents/Title/SubTitle'
import AddWebhookTemplate from '../../UIComponents/TemplatesForm/AddWebhookTemplate'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addWebhooks, resetWebhooks }, dispatch)

const mapStateToProps = (state) => {
  const marketplaceId =
    state.marketplace &&
    state.marketplace.details &&
    state.marketplace.details.id
  const { addWebhooksSuccess, addWebhooksRequesting } = state.webhooks

  return {
    marketplaceId,
    addWebhooksSuccess,
    addWebhooksRequesting
  }
}

const mapWebhooksEventsToSelect = (webhooksEvents) => {
  const events = []
  webhooksEvents.forEach((eventType) => {
    if (eventType.label !== 'all') {
      events.push({
        // replace special characters with space and capitalize first letter
        label: eventType.label
          .replace(/[^a-zA-Z0-9]/g, ' ')
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          }),
        options: eventType.events.map((event) => ({
          value: event,
          label: event
        }))
      })
    }
  })

  return events
}

class AddWebhookComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showValidation: false,
      disableSubmitButton: false,
      showMFA: false,
      values: {
        method: 'post',
        url: '',
        description: '',
        event: []
      },
      options: {
        template: (locals) => AddWebhookTemplate(locals),
        fields: {
          method: {
            label: 'Método',
            factory: RadioGroupFactory,
            autocomplete: false,
            config: {
              options: [
                { value: 'POST', label: 'POST' },
                { value: 'PUT', label: 'PUT' }
              ]
            }
          },
          url: {
            label: 'Endpoint',
            factory: InputDefaultFactory,
            autocomplete: false,
            config: {
              id: 'endpoint'
            }
          },
          description: {
            label: 'Descrição',
            factory: InputDefaultFactory,
            autocomplete: false,
            config: {
              id: 'description'
            }
          },
          event: {
            label: 'Eventos',
            factory: SelectMultiplyWithGroupFactory,
            config: {
              id: 'event',
              listItems: mapWebhooksEventsToSelect(this.props.eventsTypes),
              placeholder: 'Selecione os eventos',
              placeholderSearch: 'Buscar evento',
              notFoundLabel: 'Nenhum resultado encontrado'
            }
          }
        }
      }
    }
    this.struct = t.struct({
      method: formValidators.String,
      url: formValidators.String,
      description: formValidators.String,
      event: formValidators.List
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.eventsTypes !== this.props.eventsTypes) {
      this.setState((state) => ({
        options: {
          ...state.options,
          fields: {
            ...state.options.fields,
            event: {
              ...state.options.fields.event,
              config: {
                ...state.options.fields.event.config,
                listItems: mapWebhooksEventsToSelect(nextProps.eventsTypes)
              }
            }
          }
        }
      }))
    }

    if (nextProps.addWebhooksSuccess !== this.props.addWebhooksSuccess) {
      this.setState({
        showValidation: false,
        disableSubmitButton: false,
        values: { method: 'post', url: '', description: '', event: [] }
      })
      this.props.resetWebhooks()
    }
    return nextProps !== this.props || nextState !== this.state
  }

  handleValues = (values) => {
    let { options } = this.state
    options.fields = mapValues(options.fields, (field) => {
      return {
        ...field,
        hasError: false,
        error: null
      }
    })
    this.setState({
      values,
      options,
      showValidation: false,
      disableSubmitButton: false
    })
  }

  getFieldValidation = (fieldName) => {
    if (!this.state.values[fieldName])
      return { message: 'Você esqueceu de preencher aqui?' }
    if (fieldName === 'event') {
      if (this.state.values[fieldName].length === 0) {
        return { message: 'Você esqueceu de selecionar aqui?' }
      }
    }

    return t.validate(this.state.values, this.struct).errors.find((error) => {
      return error.path.includes(fieldName)
    })
  }

  handleOpenMFA = () => {
    const { values } = this.state
    const isFormValid = t.validate(values, this.struct).isValid()

    if (!isFormValid) {
      this.setState({ disableSubmitButton: true })
      return this.setState({ showValidation: true })
    }

    this.setState({ showMFA: true })
  }

  setShowMFA = (show) => {
    this.setState({ showMFA: show })
  }

  onSubmit = async () => {
    const { values } = this.state
    const isFormValid = t.validate(values, this.struct).isValid()

    if (!isFormValid) {
      this.setState({ disableSubmitButton: true })
      return this.setState({ showValidation: true })
    }

    const allSelected =
      values.event.length ===
      mapWebhooksEventsToSelect(this.props.eventsTypes)
        .map((category) => category.options.map((option) => option.value))
        .flat()
        .flat().length

    if (allSelected) {
      this.props.addWebhooks(this.props.marketplaceId, {
        ...values,
        event: ['all']
      })
    } else {
      this.props.addWebhooks(this.props.marketplaceId, values)
    }
  }

  render() {
    let { values, options, showValidation, disableSubmitButton } = this.state
    let { addWebhooksRequesting } = this.props

    if (showValidation) {
      options.fields = mapValues(options.fields, (field, key) => {
        const fieldValidation = this.getFieldValidation(key)

        return {
          ...field,
          hasError:
            fieldValidation && fieldValidation.hasOwnProperty('message'),
          error: fieldValidation && fieldValidation.message
        }
      })
    }

    return (
      <Fragment>
        {this.state.showMFA && (
          <MFASingleComponent
            showComponent={this.setShowMFA}
            onSubmit={this.onSubmit}
          />
        )}
        <StyledCard padding={'2rem 3rem'}>
          <Grid noPadding>
            <Grid.Row bigMarginBottom>
              <Grid.Col cols={2}>
                <SubTitle themeColor>
                  Criar <strong>Webhook</strong>
                </SubTitle>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row auto>
              <FormZ
                options={options}
                struct={this.struct}
                values={values}
                onChange={this.handleValues}
                labelButton='Criar Webhook'
                onSubmitForm={() => this.handleOpenMFA()}
                isRequesting={addWebhooksRequesting}
                disableSubmitButton={
                  disableSubmitButton && !addWebhooksRequesting
                }
              />
            </Grid.Row>
          </Grid>
        </StyledCard>
      </Fragment>
    )
  }
}

AddWebhookComponent.propTypes = {
  marketplaceId: PropTypes.string,
  addWebhooks: PropTypes.func,
  resetWebhooks: PropTypes.func,
  addWebhooksSuccess: PropTypes.bool,
  addWebhooksRequesting: PropTypes.bool,
  eventsTypes: PropTypes.array
}

const StyledCard = styled(Card)`
  & article + span {
    position: absolute;
    margin-top: 6px;
  }
`

export default connect(mapStateToProps, mapDispatchToProps)(AddWebhookComponent)
