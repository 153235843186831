/* eslint react/prop-types: 0 */
import React, { Fragment, useEffect, useState } from 'react'
import { darkGray, normalText } from '../../Styles/settings/Constants'
import { deletetUserProfile, getUsersList, updateUserProfile } from '../../Actions/user'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../UIComponents/Button/ButtonDefault'
import Can from '../../Helpers/Permission/Can'
import Grid from '../../UIComponents/Grid/Grid'
import IconButton from 'src/UIComponents/IconButton/IconButton'
import InviteUserComponent from './InviteUserComponent'
import Label from '../../UIComponents/Label/Label'
import { ProfileTypes } from '../../Constants/ProfileTypes'
import ProfilesComponent from '../ProfilesComponent/ProfilesComponent'
import Select from '../../UIComponents/Select/Select'
import Sidebar from '../../UIComponents/Sidebar/Sidebar'
import SubTitle from '../../UIComponents/Title/SubTitle'
import Table from '../Table/NewTable'
import TableEditCell from '../../UIComponents/TableEditCell/TableEditCell'
import Title from '../../UIComponents/Title/Title'
import TitleInfoPage from '../../UIComponents/Title/TitleInfoPage'
import moment from 'moment'
import { MFASingleComponent } from '../MFA/mfaSingleComponent'

const UsersComponent = () => {
  const dispatch = useDispatch()
  const [ state, setState ] = useState({
    edit: false,
    limit: 10,
    profiles: false,
    item: null,
    newProfile: null,
    showEdit: false,
    showDelete: false
  })
  const [showMFA, setShowMFA] = useState(false)
  const [userMFA, setUserMFA] = useState(false)

  const {
    marketplaceId,
    marketplaceColor,
    usersListRequesting,
    userDeleteRequesting,
    usersList,
    totalPages,
    usersListError
  } = useSelector(({ marketplace, custom, user }) => ({
    marketplaceId: marketplace && marketplace.details && marketplace.details.id,
    marketplaceColor: custom.color,
    usersListRequesting: user.usersListRequesting,
    userDeleteRequesting: user.userDeleteRequesting,
    usersList: user.usersList,
    totalPages: user.totalPages,
    usersListError: user.usersListError
  }))

  useEffect(() => {
    dispatch(getUsersList())
  }, [])

  const handleSidebar = () => setState({ ...state, profiles: !state.profiles })

  const changeProfileConfirm = (newProfile, item) => {
    setState({ ...state,
      item: {
        ...item,
        profile: {
          ...item.profile,
          dashboard_layout: newProfile.value
        }
      },
      newProfile,
      showEdit: true })
  }

  const deleteUserProfileConfirm = item => {
    setState({ ...state, item, showDelete: true })
  }

  const deleteUser = userId => {
    dispatch(deletetUserProfile(marketplaceId, userId))
    closeChangeProfile()
  }

  const closeChangeProfile = () => {
    setState({ ...state, item: null, newProfile: null, showEdit: false, showDelete: false })
  }

  const updateProfile = userId => {
    if (state.newProfile.value === 'full_access_admin') {
      setShowMFA(true)
      setUserMFA(userId)
    } else {
      changeProfileMFA(userId)
    }
  }

  const changeProfileMFA = (userId = null) => {
    const userIdentifier = userId != null ? userId : userMFA
    dispatch(updateUserProfile(marketplaceId, userIdentifier, state.newProfile))
    closeChangeProfile()
  }

  const columns = [
    {
      Header: 'Data de criação',
      accessor: 'created_at',
      Cell: ({ cell }) => <Label fontSize={normalText} color={darkGray}>{moment(cell.value).format('DD/MM/YYYY')}</Label>,
      width: '15%'
    },
    {
      Header: 'Nome de usuário',
      accessor: 'fullName',
      Cell: ({ cell }) => <Label overflow fontSize={normalText} color={darkGray}>{cell.value}</Label>,
      width: '30%',
      sortable: true
    },
    {
      Header: 'E-mail',
      accessor: 'username',
      Cell: ({ cell }) => <Label overflow fontSize={normalText} color={darkGray}>{cell.value}</Label>,
      width: '30%'
    }, {
      Header: 'Perfil',
      accessor: 'profile',
      getCellProps: (stateRow, rowInfo) => {
        if (state.item && rowInfo.original.id === state.item.id && (state.showEdit || state.showDelete)) {
          return {
            style: { backgroundColor: marketplaceColor, borderRadius: '0 5px 5px 0', height: '100%' }
          }
        }
        return {}
      },
      Cell: ({ row }) => {
        const { original } = row
        const userProfile = ProfileTypes.find(profile => profile.value === original.profile.dashboard_layout)

        const profileLabel = userProfile ? userProfile.label : (original.profile && original.profile.dashboard_layout && 'Perfil personalizado') || 'Nenhum perfil associado'

        if (state.showEdit && state.newProfile && state.item.id === original.id) {
          return (
            <TableEditCell
              action={() => updateProfile(state.item.id)}
              close={closeChangeProfile}
            >
              Deseja alterar o perfil desse usuário para <strong>{state.newProfile.label}</strong>?
            </TableEditCell>
          )
        }
        if (state.showDelete && state.item.id === original.id) {
          return (
            <TableEditCell
              action={() => deleteUser(state.item.id)}
              close={closeChangeProfile}
            >
              Deseja <strong>excluir</strong> o cadastro desse usuário?
            </TableEditCell>
          )
        }

        return (
          <Grid noPadding>
            <Grid.Row>
              <Grid.Col cols={10}>
                <Can I='update' a='User'>
                  <Select options={ProfileTypes} value={userProfile} placeholder={profileLabel} onChange={value => changeProfileConfirm(value, original)} />
                </Can>
                <Can not do='update' on='User'>
                  <Label fontSize={normalText} color={darkGray}>{profileLabel}</Label>
                </Can>
              </Grid.Col>
              <Can I='delete' a='User'>
                <Grid.Col offset={4} cols={2}>
                  <IconButton outline src='/assets/icons/actions/delete.svg' onClick={() => deleteUserProfileConfirm(original)} data-test={`config-usuarios-item-${original.id}`} />
                </Grid.Col>
              </Can>
            </Grid.Row>
          </Grid>
        )
      },
      sortable: false,
      width: '20%'
    }
  ]

  return (
    <Fragment>
      {showMFA && (
        <MFASingleComponent showComponent={setShowMFA} onSubmit={changeProfileMFA} />
      )}
      <Sidebar show={state.profiles}>
        <ProfilesComponent handleClose={handleSidebar} />
      </Sidebar>
      <Grid noPadding>
        <TitleInfoPage>Configurações do marketplace {'>'}</TitleInfoPage>
        <Grid.Row>
          <Grid.Col offset={1} cols={13}>
            <Title big noPadding data-test='config-usuarios-title'>
              <strong>Usuários</strong>
            </Title>
          </Grid.Col>
          <Grid.Col cols={2}>
            <Button
              inversed
              style={{ marginTop: '-.7rem' }}
              onClick={handleSidebar}
              data-test='config-usuarios-verPerfis-btn'
            >
              Ver Perfis
            </Button>
          </Grid.Col>
        </Grid.Row>
        <Can I='create' on='User'>
          <Grid.Row largeMarginBottom>
            <Grid.Col offset={1} cols={15}>
              <InviteUserComponent />
            </Grid.Col>
          </Grid.Row>
        </Can>
        <Grid.Row>
          <Grid.Col offset={1} cols={15}>
            <SubTitle line>
              Usuários <strong>Cadastrados</strong>
            </SubTitle>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col offset={1} cols={15}>
            <Table
              loading={usersListRequesting || userDeleteRequesting}
              tableColumns={columns}
              tableData={usersList}
              totalPages={totalPages}
              pageSize={state.limit}
              error={usersListError}
              iconComponent={null}
              emptyState={{}}
              heightRow
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

export default UsersComponent
