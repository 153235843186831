import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ToggleComponent = ({ defaultStatus, onlyActive, handleOnChange }) => {
  const [status, setStatus] = useState(defaultStatus)

  const handleToggleOnChange = () => {
    setStatus((prev) => {
      handleOnChange(!prev)
      return !prev
    })
  }

  return (
    <SwitchContainer>
      <Toggle className='toggle' onClick={!onlyActive && handleToggleOnChange}>
        <div className={`toggle__background ${status && 'toggle__background__animate'}`} />
        <div className={`toggle__ball ${status && 'toggle__ball__animate'}`} />
      </Toggle>
    </SwitchContainer>
  )
}

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: end;

  >span {
    margin-bottom: -10px;
  }
`

const Toggle = styled.div`
margin-top: 1.5rem;
position: relative;
display: inline-block;
width: 40px;
height: 20px;

&:hover {
cursor: pointer;
}

.toggle__background {
  width: 100%;
  height: 100%;
  border-radius:1em;
  background-color: rgba(204, 204, 204, 0.4);
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle__ball{
  position: absolute;
  left:0;
  top:0;
  height: 100%;
  width: 18px;
  border-radius:50%;
  background-color: ${props => props.theme.color} !important;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle__ball__animate{
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.toggle__background__animate{
  background-color: ${props => props.theme.color} !important;
  opacity: 0.4;
  -webkit-transition: .4s;
  transition: .4s;
}
`

ToggleComponent.propTypes = {
  defaultStatus: PropTypes.bool,
  onlyActive: PropTypes.bool,
  handleOnChange: PropTypes.func
}

export default ToggleComponent
