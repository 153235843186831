import React from 'react'
import { useSelector } from 'react-redux'
import TopHeaderSellerUI from 'src/UIComponents/TopHeader/TopHeaderSeller'

const TopHeaderSeller = () => {
  const {
    marketplace
  } = useSelector(({ user, marketplace, context }) => {
    const fullName = user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : user.firstName
        ? user.firstName
        : user.lastName
    return {
      user: {
        name: fullName || user.username,
        userName: user.username,
        permissions: user.permissions
      },
      marketplaceId: (context.marketplace && context.marketplace.id) || marketplace.id,
      marketplace: {
        name: marketplace.details.name,
        details_name: marketplace.details.customer.statement_descriptor,
        document: marketplace.details.customer.ein
      }
    }
  })

  return (
    <>
      <TopHeaderSellerUI marketplace={marketplace} />
    </>
  )
}

export default TopHeaderSeller
