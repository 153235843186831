import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import TransferDetail from './TransferDetail'
import { useQueryParams } from '../../Hooks/useQueryParams'
import { useSelector } from 'react-redux'

const TransferDetailComponent = () => {
  const { seller } = useSelector(({ context }) => context)
  const queryParams = useQueryParams()
  const history = useHistory()

  const matchFromBalancesList = useRouteMatch({
    path: '*/extrato/transferencia/:id'
  })

  const matchFromTransferDetail = useRouteMatch({
    path: '*/transferencia/:id'
  })

  const matchFromTransferList = useRouteMatch({
    path: '*/transferencias/detalhe/:id'
  })

  const match = matchFromTransferDetail || matchFromTransferList || matchFromBalancesList

  const [showSidebar, setShowSidebar] = useState(!!match)
  const [transferId, setTransferId] = useState()

  const shouldShowBackButton = !matchFromBalancesList && matchFromTransferDetail
  const isTransferAdjustment = queryParams.get('transferType') === 'adjustment'

  useEffect(() => {
    if (match) {
      if (match.params['id'] !== transferId) {
        setTransferId(match.params['id'])
        setTimeout(() => {
          setShowSidebar(true)
        }, 500)
      }
    } else {
      setShowSidebar(false)
      setTimeout(() => {
        setTransferId(null)
      }, 500)
    }
  }, [match])

  const handleClose = () => {
    setShowSidebar(false)
    setTimeout(() => {
      setTransferId(null)
      const urlSplitted = match.url.split('/')
      if (matchFromTransferList) {
        history.push(`${match.params[0]}/transferencias`)
      } else if (seller?.id) {
        history.push(`/${urlSplitted[1]}/${urlSplitted[2]}/${urlSplitted[3]}`)
      } else {
        history.push(`${match.params[0]}`)
      }
    }, 500)
  }

  const handleBack = () => {
    history.push(`${match.params[0]}`)
  }

  return (
    <>
      {
        match && transferId && <Sidebar show={showSidebar}>
          <TransferDetail handleGoBack={shouldShowBackButton && handleBack} handleClose={handleClose} transferId={transferId} isAdjustment={isTransferAdjustment} />
        </Sidebar>
      }
    </>
  )
}

export default TransferDetailComponent
