import React, { useRef, createRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../../UIComponents/Button/ButtonDefault'
import { Modal } from '../../UIComponents/Modal/Modal'
import Label from '../../UIComponents/Label/Label'
import { biggestText, bigText, darkGray, normalText, textBold } from '../../Styles/settings/Constants'

const CookiePolicy = ({ handleOnClose }) => {
  const SUMMARY = [
    {
      title: '1. OBJETIVO'
    },
    {
      title: '2. ABRANGÊNCIA'
    },
    {
      title: '3. DEFINIÇÕES'
    },
    {
      title: '3.1. TERMINOLOGIA'
    },
    {
      title: '4. CONSIDERAÇÕES SOBRE COOKIES'
    },
    {
      title: '4.1. O QUE SÃO COOKIES E QUAIS AS SUAS CLASSIFICAÇÕES?'
    },
    {
      title: '4.2. PARA QUAL FINALIDADE OS COOKIES SÃO UTILIZADOS?'
    },
    {
      title: '4.3. UTILIZAMOS TECNOLOGIA DE RASTREAMENTO?'
    },
    {
      title: '4.4. COOKIES DE TERCEIROS'
    },
    {
      title: '5. DISPOSIÇÕES GERAIS'
    }
  ]

  const POLICIES = [
    {
      title: '1. OBJETIVO',
      text: (
        <p>
          Definir regras e diretrizes para o sobre cookies (Aviso) e explicar como a ZOOP usa estes cookies e outras tecnologias de rastreamento nos seguintes
          websites https://zoop.com.br/ e seus subdomínios.'
        </p>
      )
    },
    {
      title: '2. ABRANGÊNCIA',
      text: <p>Todas as pessoas que acessam os websites https://zoop.com.br/ e seus subdomínios.</p>
    },
    {
      title: '3. DEFINIÇÕES',
      subItems: [
        {
          title: '3.1. TERMINOLOGIA',
          text: (
            <>
              <p>
                <Label fontSize={normalText} bold>
                  Pixel Tag:
                </Label>{' '}
                pequenos blocos de código de uma página da web que permite que elas realizem ações como ler e armazenar cookies e transmitir informações para
                ZOOP. A conexão resultante pode incluir informações com endereço de IP de um dispositivo, a hora em que uma pessoa visualizou o pixel, um
                identificador associado ao navegador ou dispositivo e o tipo de navegador em uso.
              </p>
              <br />
              <p>
                <Label fontSize={normalText} bold>
                  Identificador de dispositivo:
                </Label>{' '}
                códigos que possibilitam a identificação do um dispositivo móvel, seja de maneira persistente ou transitória, tais como o ID de Publicidade ou o
                ID do sistema operacional.
              </p>
              <br />
              <p>
                ZOOP: ZOOP TECNOLOGA E MEIOS DE PAGAMENTO S.A., pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 19.468.242/0001-32, com endereço
                na Avenida das Américas, nº 700, Bloco 5, salas 101 a 104 e 301 a 316, bairro Barra da Tijuca, cidade do Rio Janeiro, CEP: 22640-100;
              </p>
              <br />
              <p>
                <Label fontSize={normalText} bold>
                  Tratamento de dados pessoais:
                </Label>{' '}
                toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução,
                transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação,
                comunicação, transferência, difusão ou extração.
              </p>
            </>
          )
        }
      ]
    },
    {
      title: '4. CONSIDERAÇÕES SOBRE COOKIES',
      subItems: [
        {
          title: '4.1. O QUE SÃO COOKIES E QUAIS AS SUAS CLASSIFICAÇÕES?',
          text: (
            <>
              <p>
                Cookies são arquivos que contém um identificador (uma sequência de letras e números) enviados por um servidor para determinado navegador
                (browser) que o armazena. O identificador é então enviado de volta ao servidor sempre que o navegador tenta acessar uma página do servidor.
              </p>
              <br />
              <p>
                Além disso, podemos classificar os cookies como “persistentes” ou “de sessão”. Um Cookie persistente será armazenado por um navegador e
                permanecerá válido até sua data de validade, salvo se deletado pelo usuário antes desta data. Um Cookie de sessão, por outro lado, irá expirar
                ao final de uma sessão de navegação do usuário, quando o navegador é encerrado.
              </p>
              <br />
              <p>
                A ZOOP trata as informações obtidas por meio de cookies e outras tecnologias como informações não-pessoais. Entretanto, na medida que o endereço
                IP ou identificadores semelhantes sejam considerados informações pessoais pela legislação aplicável, a ZOOP tratará esses indicadores como
                informação pessoal.
              </p>
            </>
          )
        },
        {
          title: '4.2. PARA QUAL FINALIDADE OS COOKIES SÃO UTILIZADOS?',
          text: (
            <>
              A ZOOP utiliza cookies e outras tecnologias com as seguintes finalidades:
              <br />
              <br />
              a){' '}
              <Label fontSize={normalText} bold>
                Autenticação:
              </Label>{' '}
              Indica que o usuário está conectado, para que seja possível oferecer os recursos apropriados, bem como entender como utiliza e acessa os websites
              da ZOOP;
              <br />
              <br />
              b){' '}
              <Label fontSize={normalText} bold>
                Anúncios e medição:
              </Label>{' '}
              ajuda a analisar comportamentos de navegação, a fim de analisar o conteúdo e uso de produtos e serviços;
              <br />
              <br />
              c){' '}
              <Label fontSize={normalText} bold>
                Segurança e integridade:
              </Label>{' '}
              ajuda a manter a segurança dos serviços, oferecendo suporte ou ativando recursos de segurança e ajudando a detectar atividades que violam nossas
              políticas legais;
              <br />
              <br />
              d){' '}
              <Label fontSize={normalText} bold>
                Recursos e serviços:
              </Label>{' '}
              ajuda a fornecer produtos e serviços e conteúdo personalizado;
              <br />
              <br />
              e){' '}
              <Label fontSize={normalText} bold>
                Desempenho:
              </Label>{' '}
              ajuda a proporcionar a melhor experencia possível, por exemplo, ajudando a rotear tráfego entre servidores e perceber com velocidade o serviço é
              carregado para diversas pessoas. Ocasionalmente, poderão ser armazenadas informações no navegador ou dispositivo para que os recursos em uso
              carreguem e respondam rapidamente.
              <br />
              <br />
              <p>
                O usuário poderá gerenciar as suas preferências de cookies e outras tecnologias semelhantes a partir da configuração de seu navegador ou
                dispositivo, recusando ou excluindo determinados cookies e outras tecnologias. Contudo, isso poderá comprometer a prestação dos serviços ou
                impedir o funcionamento de determinadas funcionalidades que os integram. Abaixo, listamos alguns endereços eletrônicos onde o usuário poderá
                gerenciar tais preferências:
              </p>
              <br />
              <li>
                <a href='https://support.google.com/chrome/answer/95647'>
                  <Label fontSize={normalText}>https://support.google.com/chrome/answer/95647</Label>{' '}
                  <Label fontSize={normalText} color={darkGray} bold>
                    (Chrome);
                  </Label>
                </a>
              </li>
              <li>
                <a href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'>
                  <Label fontSize={normalText}>https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</Label>{' '}
                  <Label fontSize={normalText} color={darkGray} bold>
                    (Firefox);
                  </Label>
                </a>
              </li>
              <li>
                <a href='https://www.opera.com/help/tutorials/security/cookies/'>
                  <Label fontSize={normalText}>https://www.opera.com/help/tutorials/security/cookies/</Label>{' '}
                  <Label fontSize={normalText} color={darkGray} bold>
                    (Opera);
                  </Label>
                </a>
              </li>
              <li>
                <a href='https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies'>
                  <Label fontSize={normalText}>https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</Label>{' '}
                  <Label fontSize={normalText} color={darkGray} bold>
                    (Internet Explorer);
                  </Label>
                </a>
              </li>
              <li>
                <a href='https://support.apple.com/pt-br/guide/safari/sfri11471/mac'>
                  <Label fontSize={normalText}>https://support.apple.com/pt-br/guide/safari/sfri11471/mac</Label>{' '}
                  <Label fontSize={normalText} color={darkGray} bold>
                    (Safari);
                  </Label>
                </a>
              </li>
              <li>
                <a href='https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy'>
                  <Label fontSize={normalText}>https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</Label>{' '}
                  <Label fontSize={normalText} color={darkGray} bold>
                    (Edge).
                  </Label>
                </a>
              </li>
            </>
          )
        },
        {
          title: '4.3. UTILIZAMOS TECNOLOGIA DE RASTREAMENTO?',
          text: 'A ZOOP poderá utilizar outras tecnologias de rastreamento, como web beacons (às vezes chamados de “pixels de rastreamento”, “pixel tags” ou “gifs nítidos”), usados geralmente para contar a quantidade de usuários que acessam determinadas páginas de nosso website, oferecer serviços e ajudar a avaliar a eficácia de campanhas. Em muitos casos, essas tecnologias dependem de cookies para funcionar corretamente.'
        },
        {
          title: '4.4. COOKIES DE TERCEIROS',
          text: (
            <>
              <p>
                Os provedores de serviços utilizados pela ZOOP poderão utilizar cookies e outras tecnologias de sua propriedade para identificar o navegador e
                dispositivo utilizado, de modo a oferecer publicidade direcionada da ZOOP quando o usuário acessa websites ou aplicativos de terceiros. Esses
                terceiros podem também fornecer à ZOOP informações acerca do desempenho das campanhas de marketing desenvolvidas.
              </p>
              <br />
              <p>
                Ademais, o usuário poderá realizar o “opt-out” (exclusão voluntária) dos serviços de publicidade de terceiro acima mencionadas através das
                ferramentas:
              </p>
              <br />
              a){' '}
              <Label fontSize={normalText} color={darkGray} bold>
                “YourAdChoices”
              </Label>{' '}
              -{' '}
              <a href='http://optout.aboutads.info/?c=2&lang=EN'>
                <Label fontSize={normalText}>http://optout.aboutads.info/?c=2&lang=EN</Label>
              </a>
              <br />
              <br />
              b){' '}
              <Label fontSize={normalText} color={darkGray} bold>
                “Evidon”
              </Label>{' '}
              -{' '}
              <a href='http://info.evidon.com/companies?cc=pt_br&external_opt_out=true&opt_out=true&opt_out_only=true'>
                <Label fontSize={normalText}>http://info.evidon.com/companies?cc=pt_br&external_opt_out=true&opt_out=true&opt_out_only=true</Label>
              </a>
            </>
          )
        }
      ]
    },
    {
      title: '5. DISPOSIÇÕES GERAIS',
      text: (
        <>
          <p>
            Ao navegar pelos websites da ZOOP e utilizar suas funcionalidades, os usuários aceitam todo o disposto nesta Política e demais políticas legais que
            se encontram vigentes na data de acesso. Por isso, é recomendável que os usuários se mantenham atualizados.
          </p>
          <br />
          <p>
            A presente Política de cookies está sujeita a constante melhoria e aprimoramento. Assim, a ZOOP se reserva o direito de modificá-la a qualquer
            momento, conforme a finalidade dos websites ZOOP, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força
            jurídica equivalente, cabendo aos usuários verificá-la sempre que efetuar o acesso aos websites da ZOOP.
          </p>
          <br />
          <p>
            A eventual tolerância quanto a qualquer violação dos termos e condições do contido neste domínio será considerada mera liberalidade e não será
            interpretada como novação, precedente invocável, renúncia a direitos, alteração tácita dos termos contratuais, direito adquirido ou alteração
            contratual.
          </p>
          <br />
          <p>
            Caso alguma disposição desta Política for julgada inaplicável ou sem efeito, o restante das normas continua a viger, sem a necessidade de medida
            judicial que declare tal assertiva. Os termos aqui descritos serão interpretados segundo a legislação brasileira.
          </p>
          <br />
          <p>
            A comunicação entre a ZOOP e o usuário deverá ser realizada através do endereço de e-mail{' '}
            <Label fontSize={normalText}>privacidade@zoop.com.br</Label>.
          </p>
          <br />
          <p>
            Sempre que possível, potenciais divergências entre o usuário e a ZOOP serão resolvidas de forma amigável. Quando todos os esforços neste sentido
            forem esgotados, você concorda, desde já, que fica eleito o foro da comarca de São Paulo, no Estado de São Paulo, para resolver controvérsias ou
            queixas oriundas da utilização de nossa plataforma ou relacionadas a esta Política de cookies.
          </p>
        </>
      )
    }
  ]

  const elementsRef = useRef(SUMMARY.map(() => createRef()))

  const getSummaryIndex = (title) => {
    return SUMMARY.map((item) => item.title).indexOf(title)
  }

  const scrollTo = (index) => {
    debugger
    elementsRef.current[index].current.scrollIntoView()
  }

  return (
    <Modal handleOnClose={handleOnClose}>
      <Modal.Header>Política de Cookies</Modal.Header>
      <Modal.Body>
        <Summary>
          {SUMMARY.map((item, index) => (
            <Label key={index} onClick={() => scrollTo(index)} color={darkGray} fontSize={bigText}>
              {item.title}
            </Label>
          ))}
        </Summary>
        <Policies>
          {POLICIES.map((item, itemIndex) => (
            <Policy key={itemIndex}>
              <div ref={elementsRef.current[getSummaryIndex(item.title)]}>
                <Label color={darkGray} fontSize={biggestText} bold>
                  {item.title}
                </Label>
              </div>
              <br />
              {item.text && (
                <Label color={darkGray} fontSize={normalText}>
                  {item.text}
                </Label>
              )}
              {item.subItems &&
                item.subItems.map((subItem, subItemIndex) => (
                  <>
                    {subItemIndex > 0 && <br />}
                    <div ref={elementsRef.current[getSummaryIndex(subItem.title)]}>
                      <Label color={darkGray} fontSize={bigText} bold>
                        {subItem.title}
                      </Label>
                    </div>
                    <br />
                    <Label color={darkGray} fontSize={normalText}>
                      {subItem.text}
                    </Label>
                  </>
                ))}
              <br />
              <br />
            </Policy>
          ))}
        </Policies>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOnClose} widthAuto>
          Entendi
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

CookiePolicy.propTypes = {
  handleOnClose: PropTypes.func
}

export { CookiePolicy }

const Summary = styled.div`
  display: flex;
  flex-direction: column;

  span {
    line-height: 30px;

    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.color};
      font-weight: ${textBold};
    }
  }
`

const Policies = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
`

const Policy = styled.div`
  display: flex;
  flex-direction: column;
`
