import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import HeaderSidebar from 'src/UIComponents/HeaderSidebar/HeaderSidebar'
import Grid from 'src/UIComponents/Grid/Grid'
import Title from 'src/UIComponents/Title/Title'
import StyledSketchPicker from 'src/UIComponents/SketchColorPicker/SketchColorPicker'
import FileInstructionsFragment from 'src/Components/VisualIdentitySidebar/FileInstructionsFragment'
import FileDrop from 'src/Components/FileDrop/FileDrop'
import Button from 'src/UIComponents/Button/ButtonDefault'
import {
  resetTemporaryColor,
  updateLogo,
  updateMarketplaceCustomColor,
  updateTemporaryColor,
  resetTemporaryLogo,
  updateTemporaryLogo, resetCustomErrors
} from 'src/Actions/custom'
import Label from 'src/UIComponents/Label/Label'
import { red } from 'src/Styles/settings/Constants'
import AlertMessage from 'src/UIComponents/AlertMessage/AlertMessage'
import OpacityGrid from 'src/UIComponents/Dropzone/OpacityGrid'
import VisualIdentityCloseDisclaimer from 'src/UIComponents/VisualIdentityCloseDisclaimer/VisualIdentityCloseDisclaimer'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'

const VisualIdentitySidebar = ({
  handleCloseSidebar
}) => {
  const [coloredLogoTypeError, setColoredLogoTypeError] = useState()
  const [coloredLogoSizeError, setColoredLogoSizeError] = useState()
  const [monochromeLogoTypeError, setMonochromeLogoTypeError] = useState()
  const [monochromeLogoSizeError, setMonochromeLogoSizeError] = useState()
  const [isDragActive, setIsDragActive] = useState(false)
  const [isUnsavedChangesDisclaimerActive, setIsUnsavedChangesDisclaimerActive] = useState(false)

  const {
    color,
    temporaryColor,
    coloredLogo,
    temporaryColoredLogo,
    monochromeLogo,
    temporaryMonochromeLogo,
    marketplaceId,
    updateCustomColoredLogoError,
    updateCustomMonochromeLogoError,
    updateColorRequesting,
    updateCustomColoredLogoRequesting,
    updateCustomMonochromeLogoRequesting
  } = useSelector(({ custom, marketplace }) => ({
    marketplaceId: marketplace.id,
    color: custom.color,
    coloredLogo: custom.logo,
    monochromeLogo: custom.monochromeLogo,
    temporaryColor: custom.temporaryColor,
    temporaryMonochromeLogo: custom.temporaryMonochromeLogo,
    temporaryColoredLogo: custom.temporaryColoredLogo,
    updateCustomColoredLogoError: custom.updateCustomColoredLogoError,
    updateCustomMonochromeLogoError: custom.updateCustomMonochromeLogoError,
    updateColorRequesting: custom.updateColorRequesting,
    updateCustomColoredLogoRequesting: custom.updateCustomColoredLogoRequesting,
    updateCustomMonochromeLogoRequesting: custom.updateCustomMonochromeLogoRequesting
  }))

  const dispatch = useDispatch()

  const resetTemporaryCustom = () => {
    dispatch(resetTemporaryColor())
    dispatch(resetTemporaryLogo('colored'))
    dispatch(resetTemporaryLogo('monochrome'))
    dispatch(resetCustomErrors('updateColorError'))
    dispatch(resetCustomErrors('updateCustomColoredLogoError'))
    dispatch(resetCustomErrors('updateCustomMonochromeLogoError'))
  }

  useEffect(() => resetTemporaryCustom(), [])

  const handleColorChange = color => {
    dispatch(updateTemporaryColor(color.hex))
  }

  const setErrorHandler = (callback, boolValue) => {
    callback(boolValue)
    setIsDragActive(false)
  }

  const handleColoredLogoEditCancel = () => {
    dispatch(resetCustomErrors('updateCustomColoredLogoError'))
    dispatch(resetTemporaryLogo('colored'))
  }

  const handleMonochromeLogoEditCancel = () => {
    dispatch(resetCustomErrors('updateCustomMonochromeLogoError'))
    dispatch(resetTemporaryLogo('monochrome'))
  }

  const handleCustomColorEditCancel = () => {
    dispatch(resetCustomErrors('updateColorError'))
    dispatch(resetTemporaryColor())
  }

  const handleFileDrop = (category, files) => {
    setIsDragActive(false)
    dispatch(updateTemporaryLogo(category, files[0]))
  }

  const handleSubmitColor = () => {
    dispatch(updateMarketplaceCustomColor(marketplaceId, temporaryColor))
  }

  const handleSubmitColoredLogo = () => {
    dispatch(updateLogo(marketplaceId, temporaryColoredLogo, 'logo-colored.png'))
  }

  const handleSubmitMonochromeLogo = () => {
    dispatch(updateLogo(marketplaceId, temporaryMonochromeLogo, 'logo-monochrome.png'))
  }

  const closeSidebar = () => {
    setColoredLogoTypeError(false)
    setColoredLogoSizeError(false)
    setMonochromeLogoTypeError(false)
    setMonochromeLogoSizeError(false)
    setIsDragActive(false)
    resetTemporaryCustom()
    setIsUnsavedChangesDisclaimerActive(false)
    handleCloseSidebar()
  }

  const tryCloseSidebar = () => {
    if ((!temporaryColor && !temporaryColoredLogo && !temporaryMonochromeLogo) || isUnsavedChangesDisclaimerActive) {
      closeSidebar()
    } else {
      setIsUnsavedChangesDisclaimerActive(true)
    }
  }

  return (
    <OpacityGrid isDragActive={isDragActive} noPadding>
      <HeaderSidebar
        title={<><strong>Editar</strong> Cor e Logo</>}
        handleClose={tryCloseSidebar} />
      <SidebarContainer>
        {
          isUnsavedChangesDisclaimerActive && (
            <Grid.Row>
              <Grid.Col offset={1} cols={14}>
                <VisualIdentityCloseDisclaimer
                  dismiss={closeSidebar}
                  submit={() => setIsUnsavedChangesDisclaimerActive(false)} />
              </Grid.Col>
            </Grid.Row>
          )
        }
        {
          !isUnsavedChangesDisclaimerActive && (
            <Grid.Row>
              <Grid.Col offset={1} cols={14}>
                <Grid.Row auto>
                  <Title noTransform small noPadding line>
                    <strong>Cor</strong> do Marketplace
                  </Title>
                </Grid.Row>
                <Grid.Row auto largeMarginBottom>
                  <StyledSketchPicker
                    onChangeComplete={handleColorChange}
                    color={temporaryColor || color}
                    disableAlpha
                    presetColors={[]} />
                </Grid.Row>
                {
                  !!temporaryColor && (
                    <Grid.Row largeMarginBottom>
                      <Grid.Col cols={5}>
                        <Button
                          disabled={updateColorRequesting}
                          inversed
                          onClick={handleCustomColorEditCancel}>
                          Cancelar
                        </Button>
                      </Grid.Col>
                      <Grid.Col cols={7}>
                        <Button
                          isLoading={updateColorRequesting}
                          type='submit'
                          onClick={handleSubmitColor}>
                          Salvar Alterações
                        </Button>
                      </Grid.Col>
                    </Grid.Row>
                  )
                }
                <Grid.Row auto>
                  <Title noTransform small noPadding line>
                    <strong>Logo</strong> Colorida
                  </Title>
                </Grid.Row>
                <Grid.Row>
                  <FileInstructionsFragment
                    dimensionsText='160px largura X 60px altura'
                    typeError={coloredLogoTypeError}
                    sizeError={coloredLogoSizeError} />
                </Grid.Row>
                {
                  (coloredLogoTypeError || coloredLogoSizeError) && (
                    <Grid.Row auto>
                      <Label color={red}>
                        A imagem selecionada não obedece as especificações acima
                      </Label>
                    </Grid.Row>
                  )
                }
                <Grid.Row auto largeMarginBottom>
                  <FileDrop
                    categoryIconSource='/assets/icons/actions/drop-file.svg'
                    changeFileLabel='Alterar o Logo'
                    categoryLabel='Logo Colorida'
                    maxSize={10 * 1000}
                    supportedMimeTypes={['image/png']}
                    onDrop={files => handleFileDrop('colored', files)}
                    file={temporaryColoredLogo ? temporaryColoredLogo.preview : coloredLogo}
                    onMaxSizeError={value => setErrorHandler(setColoredLogoSizeError, value)}
                    onMimeTypeError={value => setErrorHandler(setColoredLogoTypeError, value)}
                    onDragLeave={() => setIsDragActive(false)}
                    onDragEnter={() => setIsDragActive(true)}
                    externalError={!!updateCustomColoredLogoError} />
                </Grid.Row>
                {
                  updateCustomColoredLogoError && (
                    <Grid.Row auto largeMarginBottom>
                      <AlertMessage error={updateCustomColoredLogoError} type='error' noMargin />
                    </Grid.Row>
                  )
                }
                {
                  !!temporaryColoredLogo && (
                    <Grid.Row largeMarginBottom>
                      <Grid.Col cols={5}>
                        <Button
                          disabled={updateCustomColoredLogoRequesting}
                          inversed
                          onClick={handleColoredLogoEditCancel}>
                          Cancelar
                        </Button>
                      </Grid.Col>
                      <Grid.Col cols={7}>
                        <Button
                          isLoading={updateCustomColoredLogoRequesting}
                          type='submit'
                          onClick={handleSubmitColoredLogo}>
                          Salvar Alterações
                        </Button>
                      </Grid.Col>
                    </Grid.Row>
                  )
                }
                <Grid.Row auto>
                  <Title noTransform small noPadding line>
                    <strong>Logo</strong> Monocromática
                  </Title>
                </Grid.Row>
                <Grid.Row>
                  <FileInstructionsFragment
                    dimensionsText='240px largura X 255px altura'
                    sizeError={monochromeLogoSizeError}
                    typeError={monochromeLogoTypeError} />
                </Grid.Row>
                {
                  (monochromeLogoTypeError || monochromeLogoSizeError) && (
                    <Grid.Row auto>
                      <Label color={red}>
                        A imagem selecionada não obedece as especificações acima
                      </Label>
                    </Grid.Row>
                  )
                }
                <Grid.Row auto largeMarginBottom>
                  <FileDrop
                    categoryIconSource='/assets/icons/actions/drop-file.svg'
                    changeFileLabel='Alterar o Logo'
                    categoryLabel='Logo Monocromática'
                    maxSize={10 * 1000}
                    supportedMimeTypes={['image/png']}
                    onDrop={files => handleFileDrop('monochrome', files)}
                    file={temporaryMonochromeLogo ? temporaryMonochromeLogo.preview : monochromeLogo}
                    onMaxSizeError={value => setErrorHandler(setMonochromeLogoSizeError, value)}
                    onMimeTypeError={value => setErrorHandler(setMonochromeLogoTypeError, value)}
                    onDragLeave={() => setIsDragActive(false)}
                    onDragEnter={() => setIsDragActive(true)}
                    externalError={!!updateCustomMonochromeLogoError} />
                </Grid.Row>
                {
                  updateCustomMonochromeLogoError && (
                    <Grid.Row auto largeMarginBottom>
                      <AlertMessage error={updateCustomMonochromeLogoError} type='error' noMargin />
                    </Grid.Row>
                  )
                }
                {
                  !!temporaryMonochromeLogo && (
                    <Grid.Row largeMarginBottom>
                      <Grid.Col cols={5}>
                        <Button
                          disabled={updateCustomMonochromeLogoRequesting}
                          inversed
                          onClick={handleMonochromeLogoEditCancel}>
                          Cancelar
                        </Button>
                      </Grid.Col>
                      <Grid.Col cols={7}>
                        <Button
                          isLoading={updateCustomMonochromeLogoRequesting}
                          type='submit'
                          onClick={handleSubmitMonochromeLogo}>
                          Salvar Alterações
                        </Button>
                      </Grid.Col>
                    </Grid.Row>
                  )
                }
              </Grid.Col>
            </Grid.Row>
          )
        }
      </SidebarContainer>
    </OpacityGrid>
  )
}

VisualIdentitySidebar.propTypes = {
  handleCloseSidebar: PropTypes.func
}

export default VisualIdentitySidebar
