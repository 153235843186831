/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react'
import { darkGray, normalText } from '../../Styles/settings/Constants'
import { getWebhooks, getWebhooksEvents, removeWebhook } from '../../Actions/webhooks'
import { useDispatch, useSelector } from 'react-redux'

import AddWebhookComponent from './AddWebhookComponent'
import Can from '../../Helpers/Permission/Can'
import Grid from '../../UIComponents/Grid/Grid'
import IconButton from 'src/UIComponents/IconButton/IconButton'
import Label from '../../UIComponents/Label/Label'
import PropTypes from 'prop-types'
import SubTitle from '../../UIComponents/Title/SubTitle'
import Table from '../Table/NewTable'
import TableEditCell from '../../UIComponents/TableEditCell/TableEditCell'
import Title from '../../UIComponents/Title/Title'
import TitleInfoPage from '../../UIComponents/Title/TitleInfoPage'
import { getProfileFromMarketplace } from 'src/Helpers/Permissions'
import moment from 'moment'

const WebhooksComponent = () => {
  const dispatch = useDispatch()

  const [showDelete, setShowDelete] = useState(false)
  const [item, setItem] = useState(null)
  const [profile, setProfile] = useState()

  const {
    marketplaceColor,
    marketplaceId,
    permissions,
    webhooks,
    getWebhooksRequesting,
    getWebhooksError,
    removeWebhookRequesting,
    webhooksEvents
  } = useSelector(({ marketplace, user, custom, webhooks: webhooksState, context }) => {
    const {
      webhooks,
      getWebhooksRequesting,
      getWebhooksError,
      removeWebhookRequesting,
      webhooksEvents
    } = webhooksState

    return {
      marketplaceId: (context.marketplace && context.marketplace.id) || marketplace.id,
      permissions: user.permissions,
      marketplaceColor: custom.color,
      webhooks,
      getWebhooksRequesting,
      getWebhooksError,
      removeWebhookRequesting,
      webhooksEvents
    }
  })

  useEffect(() => {
    const profile = getProfileFromMarketplace(marketplaceId, permissions)
    setProfile(profile)
    dispatch(getWebhooksEvents())
    dispatch(getWebhooks())
  }, [])

  const deleteConfirm = item => {
    setItem(item)
    setShowDelete(true)
  }

  const deleteWebhook = () => {
    dispatch(removeWebhook(marketplaceId, item.id))
    this.setState({ showDelete: false })
  }

  const closeConfirm = () => {
    setItem(null)
    setShowDelete(false)
  }

  const columns = [
    {
      Header: 'Data de criação',
      accessor: 'created_at',
      Cell: ({ cell }) => <Label fontSize={normalText} color={darkGray}>{moment(cell.value).format('DD/MM/YYYY')}</Label>,
      width: '10%'
    },
    {
      Header: 'Método',
      accessor: 'method',
      Cell: ({ cell }) => <Label fontSize={normalText} color={darkGray}>{cell.value}</Label>,
      width: '10%'
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      Cell: ({ cell }) => <Label overflow fontSize={normalText} color={darkGray}>{cell.value}</Label>,
      width: '25%',
      sortable: false
    },
    {
      Header: 'Endpoint',
      accessor: 'url',
      Cell: ({ cell }) => <Label overflow fontSize={normalText} color={darkGray}>{cell.value}</Label>,
      width: '25%',
      sortable: false
    }
  ]

  if (profile !== 'full_visualization') {
    columns.push({
      id: 'delete_button',
      getProps: (state, rowInfo) => {
        if (showDelete && item && rowInfo.original.id === item.id) {
          return {
            style: { backgroundColor: marketplaceColor, borderRadius: '0 5px 5px 0', height: '100%' }
          }
        }
        return {}
      },
      Cell: ({ row }) => {
        const { original } = row

        if ((showDelete && item.id === original.id) || (removeWebhookRequesting && item.id === original.id)) {
          return (
            <TableEditCell
              action={() => deleteWebhook()}
              close={closeConfirm}
              isLoading={removeWebhookRequesting}
            >
              Deseja <strong>excluir</strong> este Webhook?
            </TableEditCell>
          )
        }

        return (
          <Grid noPadding>
            <Grid.Row>
              <Grid.Col cols={13} />
              <Grid.Col offset={1} cols={2}>
                <IconButton
                  outline
                  src='/assets/icons/actions/delete.svg'
                  onClick={() => deleteConfirm(original)}
                  data-test={`config-webhooks-delete-btn-${original.id}`}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        )
      },
      sortable: false
    })
  }

  return (
    <>
      <Grid noPadding>
        <TitleInfoPage>Configurações do marketplace {'>'}</TitleInfoPage>
        <Grid.Row>
          <Grid.Col offset={1} cols={15}>
            <Title big noPadding data-test='config-webhooks-title'>
              <strong>Webhooks</strong>
            </Title>
          </Grid.Col>
        </Grid.Row>
        <Can I='create' a='Webhook'>
          <Grid.Row largeMarginBottom>
            <Grid.Col offset={1} cols={15}>
              <AddWebhookComponent eventsTypes={webhooksEvents} />
            </Grid.Col>
          </Grid.Row>
        </Can>
        <Grid.Row>
          <Grid.Col offset={1} cols={15}>
            <SubTitle line>
              Webhooks <strong>Cadastrados</strong>
            </SubTitle>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col offset={1} cols={15}>
            <Table
              loading={getWebhooksRequesting}
              tableColumns={columns}
              tableData={webhooks}
              pageSize={20}
              error={getWebhooksError}
              emptyState={{}}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  )
}

WebhooksComponent.propTypes = {
  permissions: PropTypes.any,
  marketplaceId: PropTypes.string,
  marketplaceColor: PropTypes.string,
  getWebhooks: PropTypes.func,
  removeWebhook: PropTypes.func,
  webhooks: PropTypes.array,
  getWebhooksRequesting: PropTypes.bool,
  getWebhooksError: PropTypes.object,
  removeWebhookRequesting: PropTypes.bool,
  totalPages: PropTypes.number
}

export default WebhooksComponent
